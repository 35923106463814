import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import SEO from "../components/seo"
import ContentComponent from "../components/ContentComponent/ContentComponent"
import PlainContentComponent from "../components/PlainContentComponent/PlainContentComponent"

const StaticPage = ({ data }) => {
  const PageData = data?.strapiPage

  let popUpData=""

  if(PageData?.add_page_modules?.length>0){
     const results=PageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }

  return (
    <Layout popUpData={popUpData} dark={true} popularSearch={PageData?.select_popular_search?.title}>
      <div className="layout-padding-top"></div>
      
      <BreadcrumbModule
        choose_menu={PageData.choose_menu}
        pagename={PageData.title}
      />
        {PageData?.add_page_modules?.map((item, i) => {
        return (
          <div>
          {item?.__typename ===
            "STRAPI__COMPONENT_COMPONENTS_TITLE_DESC" && (
            <ContentComponent data={item} layout={PageData.layout} />
          )}
           {item?.__typename ===
            "STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT" && (
            <PlainContentComponent data={item} />
          )}
          </div>
          )
        }
        )}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
      <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/hh_logo_on_green_background_93feeb7b8b.png"

      />
  )
}

export default StaticPage

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          __typename
          id
          description {
            data {
              description
            }
          }
          title
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
          __typename
          id
          title
          content {
            data {
              content
            }
          }
        }
      }
    }
  }
`
