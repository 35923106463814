import React from 'react'
import { Container } from "react-bootstrap"
import "./PlainContentComponent.scss"

const {
    ContentModule,
  } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const PlainContentComponent = ({data}) => {
  return (
    <div className="plain-content-component">
        <Container>
        <p className="description">
            <ContentModule Content={data.content?.data?.content} />
        </p>
        </Container>
    </div>
  )
}

export default PlainContentComponent